import React from 'react';
import Layout from 'templates/Layout/Layout';
import ContentData from 'content/informations-informationOffice.json';
import InformationOffice from 'components/_shared/InformationOffice/InformationOffice';
import { Links, ImportantDates } from 'components/Informations';
import PageHeading from 'components/_shared/PageHeading/PageHeading';

const ImportantInfo = () => (
  <Layout metaTitle="Ważne informacje" metaDescription="O tym musisz pamietać...">
    <PageHeading text="Ważne informacje" />
    <ImportantDates />
    <Links />
    <InformationOffice contentData={ContentData} />
  </Layout>
);

export default ImportantInfo;