import styled from 'styled-components';
import { fontWeight, colors, breakPoints } from 'utils/variables';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainer = styled(Container)`
  position: relative;
  padding: 4rem 0 0 0;
  max-width: 75rem;
  margin: auto;

  @media (min-width: ${breakPoints.tablet}) {
    padding: 8rem 0 0 0;
    max-width: unset;
  }

  @media (min-width: ${breakPoints.desktop}) {
    padding: 10rem 0 0 0;
  }
`;

export const StyledText = styled.h1`
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 3rem;
  line-height: 4.2rem;
  font-weight: ${fontWeight.bold};
  color: ${colors.darkBlue};

  @media (min-width: ${breakPoints.tablet}) {
    font-size: 4.5rem;
    line-height: 6rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 5.6rem;
    line-height: 7rem;
  }
`;

export const StyledBackground = styled.div`
  position: absolute;
  left: 0;
  top: -100%;
  width: 100vw;
  height: 200%;
  background-color: rgb(255, 251, 244);
  background-image: linear-gradient(180deg, rgba(255, 251, 244, 1) 0%, rgba(255, 251, 244, 1) 50%, rgba(255, 255, 255, 1) 100%);
  pointer-events: none;
`;
