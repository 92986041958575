import React from 'react';
import ContentData from 'content/informations-links.json';
import SectionTitle from 'components/_shared/SectionTitle/SectionTitle';
import { StyledWrapper, StyledContainer, StyledTab } from './Links.styled';
import List from './List/List';

const Links = () => (
  <StyledWrapper>
    <StyledContainer>
      <StyledTab>
        <SectionTitle text={ContentData.files.title} />
        <List list={ContentData.files.list} type="files" />
      </StyledTab>
      <StyledTab>
        <SectionTitle text={ContentData.links.title} />
        <List list={ContentData.links.list} type="links" />
      </StyledTab>
    </StyledContainer>
  </StyledWrapper>
);

export default Links;
