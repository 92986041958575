import React from 'react';
import PropTypes from 'prop-types';
import { StyledWrapper, StyledContainer, StyledText, StyledBackground } from './PageHeading.styled';

const PageHeading = ({ text }) => (
  <StyledWrapper>
    <StyledBackground />
    <StyledContainer>
      <StyledText>{text}</StyledText>
    </StyledContainer>
  </StyledWrapper>
);

PageHeading.propTypes = {
  text: PropTypes.string.isRequired,
};

export default PageHeading;
