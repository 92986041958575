import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { formatShortWeekday, formatDateToString } from 'utils/dateUtils';
import { StyledWrapper, StyledCalendar, StyledEventBoard, StyledEventDate, StyledEventName } from './Calendar.styled';

const Calendar = ({ onClickDay, events }) => {
  if (typeof window === 'undefined') {
    return null;
  }

  const formattedEvents = events;
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentDay, setCurrentDay] = useState(null);
  const [update, setUpdate] = useState(false);
  const onChange = (date) => setCurrentDate(date);
  const onClick = () => setUpdate(!update);
  const wrapperRef = useRef(null);

  const selectDate = (event) => {
    const tileElements = Array.from(wrapperRef.current.querySelectorAll(`[aria-label="${event.date}"]`));
    tileElements.forEach((tile) => {
      tile.parentElement.classList.add('react-calendar__tile__hasEvents');
    });
  };

  const selectEvents = (elements) => {
    if (elements.length) {
      elements.map(selectDate);
    }
  };

  useEffect(() => selectEvents(formattedEvents), [update, currentDate]);

  useEffect(() => {
    setCurrentDay(formattedEvents.find((event) => event.date === formatDateToString(currentDate.toLocaleDateString())));
  }, [currentDate]);

  const getNames = (name) => <StyledEventName key={name}>{name}</StyledEventName>;

  return (
    <StyledWrapper onClick={onClick} role="presentation" ref={wrapperRef}>
      <StyledCalendar view="month" onChange={onChange} value={currentDate} formatShortWeekday={formatShortWeekday} onClickDay={onClickDay} />
      <StyledEventBoard>
        <StyledEventDate>{formatDateToString(currentDate.toLocaleDateString(), false)}</StyledEventDate>
        {currentDay ? currentDay.names.map(getNames) : 'Brak wydarzeń w tym dniu.'}
      </StyledEventBoard>
    </StyledWrapper>
  );
};

Calendar.propTypes = {
  onClickDay: PropTypes.func,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      names: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ),
};

Calendar.defaultProps = {
  onClickDay: () => {},
  events: [],
};

export default Calendar;
