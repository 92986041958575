import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StyledWrapper, StyledContainer, StyledImage, StyledContent, StyledTitle, StyledButton, StyledShape1 } from './InformationOffice.styled';
import StaticShape from '../AloneShapes/StaticShape/StaticShape';

const InformationOffice = ({ contentData }) => (
  <StyledWrapper>
    <StyledContainer>
      <StyledShape1>
        <StaticShape
          name="InformationOffice-"
          originalWidth={245}
          originalHeight={345}
          path="M 2.008 16.365 C 1.759 16.24 -0.185 15.341 0.014 12.113 C 0.08 11.049 0.189 10.083 0.463 9.004 C 1.351 5.51 3.967 0.47 6.219 0.114 C 7.525 -0.092 9.434 0.028 9.982 0.114 C 10.398 0.18 12.225 1.11 12.225 2.44 C 12.225 2.548 12.35 5.896 11.976 6.269 C 11.197 7.046 10.845 8.924 11.228 9.701 C 11.504 10.261 12.225 10.73 12.225 11.213 C 12.225 13.971 12.499 14.947 11.228 16.091 C 10.923 16.366 11.154 16.122 10.232 16.763 C 9.246 17.448 7.777 17.21 6.09 17.21 C 3.777 17.21 3.301 17.01 2.008 16.365 Z"
        />
      </StyledShape1>
      <StyledContent>
        <StyledTitle dangerouslySetInnerHTML={{ __html: contentData.title }} />
        <StyledButton as={Link} dangerouslySetInnerHTML={{ __html: contentData.button.text }} to={contentData.button.link} />
      </StyledContent>
      <StyledImage src={contentData.imageSrc} />
    </StyledContainer>
  </StyledWrapper>
);

InformationOffice.propTypes = {
  contentData: PropTypes.shape({
    title: PropTypes.string,
    button: PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string,
    }),
    imageSrc: PropTypes.string,
  }).isRequired,
};

export default InformationOffice;
