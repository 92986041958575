import styled from 'styled-components';
import { breakPoints } from 'utils/variables';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainer = styled(Container)`
  position: relative;
  padding: 6rem 0;
  max-width: 75rem;
  margin: auto;

  @media (min-width: ${breakPoints.tablet}) {
    padding: 8rem 0;
    max-width: unset;
  }

  @media (min-width: ${breakPoints.desktop}) {
    padding: 8.3rem 0 6.6rem 0;
  }
`;

export const StyledCalendarContainer = styled.div`
  padding-top: 3.5rem;
  position: relative;
`;

export const StyledShape1 = styled.div`
  pointer-events: none;
  display: inline-block;
  position: absolute;
  height: 34.5rem;
  width: 46.5rem;
  transform: rotate(270deg);
  right: -34rem;
  bottom: -7rem;

  @media (min-width: ${breakPoints.tablet}) {
    bottom: unset;
    right: -24rem;
    top: 10rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    bottom: unset;
    right: 1rem;
    top: 5.8rem;
  }
`;

export const StyledShape2 = styled.div`
  display: none;
  pointer-events: none;

  @media (min-width: ${breakPoints.tablet}) {
    display: inline-block;
    position: absolute;
    right: -28rem;
    top: 20rem;
    height: 41.426rem;
    width: 41.426rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    right: -12.5rem;
    top: 1.2rem;
  }
`;
