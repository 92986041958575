import styled from 'styled-components';
import { fontWeight, colors, breakPoints } from 'utils/variables';
import hexToRGBA from 'utils/hexToRGBA';

export const StyledList = styled.ul`
  list-style-type: none;
  position: relative;
  padding: 2.1rem 0 0 2.1rem;
  max-height: ${({ collapse }) => (collapse ? '14.5rem' : '100%')};
  overflow: hidden;
  transition: max-height 300ms ease;
`;

export const StyledCollapseButton = styled.button`
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  position: absolute;
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: ${fontWeight.light};
  color: ${colors.grayCobalt};
  cursor: pointer;
  z-index: 10;
  display: ${({ show }) => (show ? 'block' : 'none')};

  &:hover {
    color: ${hexToRGBA(colors.grayCobalt, 0.5)};
  }
`;

export const StyledListLink = styled.a`
  text-decoration: none;
  font-size: 1.6rem;
  line-height: 2.8rem;
  font-weight: ${fontWeight.light};
  color: ${colors.grayCobalt};
  margin-bottom: 2rem;

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 1.6rem;
    line-height: 3.8rem;
    margin-bottom: 3.1rem;
  }

  &:before {
    content: '→';
    margin-left: -2.1rem;
    position: absolute;
  }

  &:hover {
    color: ${hexToRGBA(colors.grayCobalt, 0.5)};
  }
`;
