import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import usePublicFileUrl from 'utils/usePublicFileUrl';
import { StyledList, StyledListLink, StyledCollapseButton } from './List.styled';

const getFilesList = (file, index) => (
  <li key={`${file.text}-${index}`}>
    <StyledListLink href={usePublicFileUrl(file.fileSrc)} download>
      {file.text}
    </StyledListLink>
  </li>
);

const getLinksList = (link, index) => (
  <li key={`${link.text}-${index}`}>
    <StyledListLink href={link.url} target="_blank" rel="noopener noreferrer">
      {link.text}
    </StyledListLink>
  </li>
);

const List = ({ list, type }) => {
  const [listCollapsed, setlistCollapsed] = useState(false);
  const [showButton, setshowButton] = useState(false);

  useEffect(() => {
    if (list.length > 4) {
      setlistCollapsed(true);
      setshowButton(true);
    } else {
      setlistCollapsed(false);
      setshowButton(false);
    }
  }, []);

  const onClick = () => setlistCollapsed(!listCollapsed);

  return (
    <>
      <StyledList collapse={listCollapsed}>{list.map(type === 'links' ? getLinksList : getFilesList)}</StyledList>
      <StyledCollapseButton role="presentation" type="button" onClick={onClick} show={showButton}>
        {listCollapsed ? 'Pokaż więcej . . .' : 'Pokaż mniej . . .'}
      </StyledCollapseButton>
    </>
  );
};

List.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string,
      fileSrc: PropTypes.string,
    }),
  ).isRequired,
  type: PropTypes.oneOf(['files', 'links']).isRequired,
};

export default List;
