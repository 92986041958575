// ----------------------------------------------------------------
// Copy file to "./static/files/" and use like:
// const publicFileUrl = usePublicFileUrl src="example-file.pdf" />;
// ----------------------------------------------------------------

import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const usePublicFileUrl = (src) => {
  const data = useStaticQuery(graphql`
    query {
      allFile {
        nodes {
          relativePath
          publicURL
        }
      }
    }
  `);

  const match = useMemo(() => data.allFile.nodes.find(({ relativePath }) => src === relativePath), [data, src]);

  return match !== undefined ? match.publicURL : null;
};

export default usePublicFileUrl;
