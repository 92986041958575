import React from 'react';
import ContentData from 'content/informations-dates.json';
import Calendar from 'components/_shared/Calendar/Calendar';
import loadable from '@loadable/component';
import SectionTitle from 'components/_shared/SectionTitle/SectionTitle';
import { colors } from 'utils/variables';
import { StyledWrapper, StyledContainer, StyledCalendarContainer, StyledShape1, StyledShape2 } from './ImportantDates.styled';

const AnimatedShape =  loadable(() => import('components/_shared/AloneShapes/AnimatedShape/AnimatedShape'));

const ImportantDates = () => (
  <StyledWrapper>
    <StyledContainer>
      <StyledShape1>
        <AnimatedShape
          name="ImportantDates-1"
          originalWidth={465}
          originalHeight={345}
          paths={[
            ' M 0.001 11.001 C 0.033 7.474 1.944 4.274 2.941 3.001 C 4.338 0.902 6.324 -0.045 8.18 0.002 C 10.176 0.052 13.869 0.052 16.364 0.002 C 20.157 0.552 21.204 1.451 22.254 2.751 C 23.35 3.601 23.345 5.22 23.151 6.651 C 23.001 7.751 20.954 10.181 20.556 10.601 C 19.029 12.21 17.009 12.765 14.169 14 C 12.419 14.761 12.073 15.923 11.474 16.511 C 10.875 17.1 10.077 17.1 8.18 17.25 C 1.777 17.25 -0.053 17 0.001 11.001 Z ',
            ' M 1.826 5.164 C 3.686 2.686 6.806 1.49 8.227 1.146 C 10.383 0.442 12.388 0.883 13.776 1.955 C 15.269 3.107 18.081 5.173 20.006 6.534 C 22.607 9.046 22.936 10.268 23.059 11.776 C 23.451 12.991 22.605 14.134 21.713 15.039 C 21.026 15.733 18.203 16.308 17.682 16.383 C 15.682 16.667 13.856 15.93 11.051 15.215 C 9.323 14.775 8.455 15.404 7.693 15.485 C 6.931 15.566 6.323 15.12 4.801 14.165 C -0.073 10.582 -1.336 9.381 1.826 5.164 Z ',
            ' M 20.133 10.402 C 17.77 13.575 14.44 15.582 12.966 16.271 C 10.691 17.52 8.811 17.456 7.672 16.554 C 6.448 15.585 4.121 13.874 2.515 12.764 C 0.49 10.51 0.428 9.212 0.63 7.551 C 0.504 6.275 1.582 4.814 2.655 3.611 C 3.48 2.686 6.385 1.438 6.914 1.243 C 8.945 0.496 10.587 0.93 13.197 1.13 C 14.805 1.253 15.795 0.363 16.563 0.109 C 17.332 -0.146 17.835 0.224 19.13 0.967 C 23.165 3.932 24.152 5.006 20.133 10.402 Z ',
          ]}
        />
      </StyledShape1>
      <StyledShape2>
        <AnimatedShape
          name="ImportantDates-2"
          originalWidth={430}
          originalHeight={430}
          fill={colors.peachMilk}
          paths={[
            ' M 1.632 5.84 L 11.893 0.914 C 13.38 0.2 15.225 0.799 16.01 2.25 L 21.141 11.737 C 21.925 13.188 21.355 14.946 19.868 15.66 L 9.607 20.586 C 8.12 21.3 6.275 20.701 5.49 19.25 L 0.359 9.763 C -0.425 8.312 0.145 6.554 1.632 5.84 Z ',
            ' M 0.831 13.726 L 4.603 2.987 C 5.15 1.43 6.878 0.549 8.459 1.02 L 18.795 4.1 C 20.376 4.572 21.216 6.218 20.669 7.774 L 16.897 18.513 C 16.35 20.07 14.622 20.951 13.041 20.48 L 2.705 17.4 C 1.124 16.928 0.284 15.282 0.831 13.726 Z ',
          ]}
        />
      </StyledShape2>
      <SectionTitle text="Ważne daty" />
      <StyledCalendarContainer>
        <Calendar events={ContentData} />
      </StyledCalendarContainer>
    </StyledContainer>
  </StyledWrapper>
);

export default ImportantDates;
