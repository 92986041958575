import styled from 'styled-components';
import ReactCalendar from 'react-calendar';
import { colors, fontWeight, breakPoints } from 'utils/variables';
import hexToRGBA from 'utils/hexToRGBA';

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${breakPoints.tablet}) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const StyledCalendar = styled(ReactCalendar)`
  width: 31.5rem;
  box-shadow: 0.9rem 0.9rem 0 0 ${colors.butter};
  border-radius: 0.2rem;
  border: 0.1rem solid ${hexToRGBA(colors.black, 0.05)};
  background-color: ${colors.white};
  max-width: calc(100% - 2rem);
  padding: 2rem;

  * {
    font-size: 1.7rem;
    line-height: 2rem;
    font-weight: ${fontWeight.light};
    color: ${colors.darkBlue};
    text-transform: capitalize;
    text-decoration: none;
  }

  .react-calendar__navigation {
    height: 4.6rem;
    align-items: flex-start;
    display: flex;
    padding-top: 0.8rem;

    * {
      font-size: 1.9rem;
      line-height: 2.2rem;
      letter-spacing: -0.07rem;
    }

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }

    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__next-button {
      width: 0;
      height: 0;
      border-top: 0.8rem solid transparent;
      border-bottom: 0.8rem solid transparent;
      overflow: hidden;
      margin-top: 0.2rem;
    }

    .react-calendar__navigation__prev-button {
      border-right: 0.9rem solid ${colors.darkBlue};
      margin-left: 0.4rem;
    }
    .react-calendar__navigation__next-button {
      border-left: 0.9rem solid ${colors.darkBlue};
      margin-right: 0.4rem;
    }
  }

  .react-calendar__navigation__label {
    pointer-events: none;
  }

  .react-calendar__month-view__weekdays {
    padding-bottom: 1.4rem;

    .react-calendar__month-view__weekdays__weekday {
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: -0.01rem;
    }
  }

  .react-calendar__tile {
    abbr {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 3rem;
      min-height: 3rem;
      border-radius: 3rem;
    }
    &.react-calendar__month-view__days__day--neighboringMonth abbr {
      color: ${colors.gray};
    }

    &:hover abbr {
      background-color: ${hexToRGBA(colors.butter, 0.5)};
    }

    &.react-calendar__tile__hasEvents abbr {
      background-color: ${colors.butter};
    }

    &.react-calendar__tile--now abbr {
      background-color: ${colors.darkBlue};
      color: ${colors.butter};
    }

    &.react-calendar__tile--active abbr {
      box-shadow: 0 0 0.2rem ${colors.black};
    }
  }

  button {
    background-color: ${colors.white};
    border: 0;
    cursor: pointer;
  }

  button.react-calendar__tile.react-calendar__month-view__days__day {
    padding: 0.74rem 0;
  }
`;

export const StyledEventBoard = styled.div`
  margin: 4rem 0 0 calc(100% - 30rem);
  width: 100%;
  font-size: 1.6rem;
  line-height: 3.8rem;
  font-weight: ${fontWeight.light};
  color: ${colors.grayCobalt};

  @media (min-width: ${breakPoints.tablet}) {
    margin: 0 0 0 8.8rem;
    width: auto;
  }
`;

export const StyledEventDate = styled.h3`
  padding: 0;
  font-size: 2rem;
  line-height: 3rem;
  font-weight: ${fontWeight.bold};
  margin: 0 0 1.2rem 0;
  color: ${colors.darkBlue};
`;

export const StyledEventName = styled.span`
  display: block;
  padding-left: 2.1rem;

  &:before {
    content: '→';
    margin-left: -2.1rem;
    position: absolute;
  }
`;
