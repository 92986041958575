import styled from 'styled-components';
import Image from 'components/_shared/Image/Image';
import { fontWeight, colors, breakPoints } from 'utils/variables';
import Button from 'components/_shared/Button/Button';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainer = styled(Container)`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  justify-content: center;
  padding: 0 0 10rem 0;

  @media (min-width: ${breakPoints.tablet}) {
    flex-direction: row;
  }

  @media (min-width: ${breakPoints.desktop}) {
    padding: 5rem 0 15rem 0;
  }
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  max-width: 65rem;
  max-height: 30rem;

  @media (min-width: ${breakPoints.tablet}) {
    width: 48.9rem;
    height: 42.9rem;
    max-width: unset;
    max-height: unset;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 3rem;
  padding: 1.5rem;
  width: 100%;
  max-width: 50rem;
  background-color: ${colors.peachMilk};

  @media (min-width: ${breakPoints.tablet}) {
    max-width: 36rem;
    padding: 6rem 3rem 6rem 6rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    max-width: 47.9rem;
  }
`;

export const StyledTitle = styled.h2`
  padding: 0;
  font-size: 2.4rem;
  line-height: 3.6rem;
  font-weight: ${fontWeight.medium};
  color: ${colors.darkBlue};
  margin: 0 0 2rem 0;
  text-align: center;

  @media (min-width: ${breakPoints.tablet}) {
    margin-bottom: 4.6rem;
    text-align: left;
  }

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 3.2rem;
    line-height: 4.8rem;
  }
`;

export const StyledButton = styled(Button)`
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${breakPoints.tablet}) {
    margin-left: 0;
  }
`;

export const StyledShape1 = styled.div`
  position: absolute;
  height: 34.5rem;
  width: 24.5rem;
  left: -1rem;
  top: -7rem;
  transform: rotateZ(270deg);
  pointer-events: none;

  @media (min-width: ${breakPoints.tablet}) {
    right: unset;
    left: 47.3rem;
    top: 23.7rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    top: 29.7rem;
  }
`;

export const StyledShape2 = styled.div`
  display: none;
  pointer-events: none;

  @media (min-width: ${breakPoints.tablet}) {
    display: inline-block;
    position: absolute;
    height: 42.9rem;
    width: 68.7rem;
    left: -2rem;
    top: 10.3rem;
  }

  @media (min-width: ${breakPoints.desktop}) {
    left: -0.5rem;
    top: 16.3rem;
  }
`;
