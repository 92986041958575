import styled from 'styled-components';
import { fontWeight, colors, breakPoints } from 'utils/variables';

const StyledWrapper = styled.h2`
  padding: 0;
  position: relative;
  font-size: 2.4rem;
  line-height: 3.6rem;
  font-weight: ${fontWeight.regular};
  color: ${colors.darkBlue};
  margin: 0 0 2rem 0;

  @media (min-width: ${breakPoints.desktop}) {
    font-size: 3.2rem;
    line-height: 4.8rem;
    margin-bottom: 3.1rem;
  }

  &:after {
    content: '';
    width: 8rem;
    height: 0.2rem;
    background-color: ${colors.darkBlue};
    position: absolute;
    left: 0;
    top: calc(100% + 0.7rem);
  }
`;

export default StyledWrapper;
