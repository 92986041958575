export const formatShortWeekday = (locale, date) => {
  const translatedNames = {
    Mon: 'Pon',
    Tue: 'Wto',
    Wed: 'Śro',
    Thu: 'Czw',
    Fri: 'Pią',
    Sat: 'Sob',
    Sun: 'Nie',
  };
  return translatedNames[date.toString().substring(0, 3)];
};

export const formatDateToString = (date, cases = true) => {
  const months = {
    1: 'Styczeń',
    2: 'Luty',
    3: 'Marzec',
    4: 'Kwiecień',
    5: 'Maj',
    6: 'Czerwiec',
    7: 'Lipiec',
    8: 'Sierpień',
    9: 'Wrzesień',
    10: 'Październik',
    11: 'Listopad',
    12: 'Grudzień',
  };

  const casesMonths = {
    1: 'stycznia',
    2: 'lutego',
    3: 'marca',
    4: 'kwietnia',
    5: 'maja',
    6: 'czerwca',
    7: 'lipca',
    8: 'sierpnia',
    9: 'września',
    10: 'października',
    11: 'listopada',
    12: 'grudnia',
  };

  const monthsCollection = cases ? casesMonths : months;

  const parts = date.toString().split('.').join('/').split('/');

  const day = Number(parts[0]).toString();
  const month = monthsCollection[Number(parts[1]).toString()];
  const year = parts[2];

  return `${day} ${month} ${year}`;
};
