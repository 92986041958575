import React from 'react';
import PropTypes from 'prop-types';
import StyledWrapper from './SectionTitle.styled';

const SectionTitle = ({ text }) => <StyledWrapper>{text}</StyledWrapper>;

SectionTitle.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SectionTitle;
