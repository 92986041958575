import styled from 'styled-components';
import { breakPoints } from 'utils/variables';
import Container from 'components/_shared/Container/Container';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledContainer = styled(Container)`
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 6rem 0 10rem 0;
  max-width: 75rem;
  margin: auto;

  @media (min-width: ${breakPoints.tablet}) {
    flex-direction: row;
    padding: 10rem 0;
    max-width: unset;
  }

  @media (min-width: ${breakPoints.desktop}) {
    padding: 10rem 0 0 0;
  }
`;

export const StyledTab = styled.div`
  width: 100%;

  @media (min-width: ${breakPoints.desktop}) {
    width: 50%;
  }

  &:not(:first-child) {
    margin: 6rem 0 0 0;

    @media (min-width: ${breakPoints.tablet}) {
      margin: 0;
    }
  }

  &:nth-child(even) {
    right: 0;
    margin-left: auto;
    text-align: right;

    @media (min-width: ${breakPoints.tablet}) {
      text-align: left;
      right: unset;
      margin-left: 0;
    }

    ul {
      padding: 2.1rem 2.1rem 0 0;

      @media (min-width: ${breakPoints.tablet}) {
        padding: 2.1rem 0 0 2.1rem;
      }
    }

    h2:after {
      right: 0;
      left: unset;

      @media (min-width: ${breakPoints.tablet}) {
        right: unset;
        left: 0;
      }
    }

    li > a:before {
      content: none;

      @media (min-width: ${breakPoints.tablet}) {
        content: '→';
      }
    }

    li > a:after {
      content: '←';
      margin-left: 0.4rem;
      position: absolute;

      @media (min-width: ${breakPoints.tablet}) {
        display: none;
      }
    }
  }
`;
